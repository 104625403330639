<template>
	<el-dialog top="3vh" title="编辑" width="500px" :visible.sync="is_show_in_page">
		<el-form status-icon label-position="left" label-width="70px">
            <el-form-item label="备注" >
				<el-input class="el_input" v-model="mark"></el-input>
			</el-form-item>
		</el-form>
		<div style="text-align:center">
			<el-button type="primary" @click="add_view_sub">提交</el-button>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
            id:String
		},
		data() {
			return {

                mark:'',
				is_show_in_page:false,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
				}
			}
		},
		methods: {

			//提交
			add_view_sub(){

				if(!this.mark){
					this.$my.other.msg({
						type:'warning',
						str:'备注为空'
					});
					return;
				}

				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'bankcard_edit_admin',
                        id:this.id,
                        mark:this.mark
					},
					callback:(data)=>{
						
						//关闭弹窗
						this.is_show_in_page=false;
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>

	.el_input{
		width:94%
	}
</style>