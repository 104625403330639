 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="115px">
			<el-form-item class="el_form_item" label="APP用户手机">
				<el-input class="el_input" v-model="form.app_user_tel" placeholder="APP用户手机" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="APP用户身份证">
				<el-input class="el_input" v-model="form.app_user_id_card_num" placeholder="APP用户身份证" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="银行卡号">
				<el-input class="el_input" v-model="form.card_num" placeholder="银行卡号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="归属姓名">
				<el-input class="el_input" v-model="form.card_user_name" placeholder="银行卡归属用户姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="归属手机">
				<el-input class="el_input" v-model="form.card_user_tel" placeholder="银行卡归属用户手机搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注搜索">
				<el-input class="el_input" v-model="form.mark" placeholder="备注搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="关系类型">
				<el-select class="el_input" v-model="form.relation" allow-create clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="夫妻" value="夫妻"></el-option>
					<el-option label="子孙" value="子孙"></el-option>
					<el-option label="父母" value="父母"></el-option>
					<el-option label="其他血亲" value="其他血亲"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="审核状态">
				<el-select class="el_input" v-model="form.check_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="待审核" value="1"></el-option>
					<el-option label="审核通过" value="2"></el-option>
					<el-option label="审核未通过" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="归属类型">
				<el-select class="el_input" v-model="form.is_owner" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="本人的卡" value="1"></el-option>
					<el-option label="他人的卡" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="open_add_view">添加</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success" @click="batch_open">批量审核</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" @selection-change="bankcard_check_pass">
				<el-table-column type="selection" width="39px"></el-table-column>
				<el-table-column label="添加时间" prop="creat_time_text"></el-table-column>
				<el-table-column label="用户姓名/电话">
					<template slot-scope="scope">
						<div>{{scope.row.app_user_info.name}}({{scope.row.app_user_info.tel}})</div>
					</template>
				</el-table-column>
				<el-table-column label="银行卡号信息">
					<template slot-scope="scope">
						<div>{{scope.row.card_num}} / {{scope.row.bank_cname}} / {{scope.row.bank_ename}}</div>
					</template>
				</el-table-column>
				<el-table-column label="银行卡归属人信息及关系">
					<template slot-scope="scope">
						<div>{{scope.row.card_user_name}}{{scope.row.card_user_tel?' / '+scope.row.card_user_tel:' / 无联系方式'}}{{scope.row.relation?' / 关系:'+scope.row.relation:''}}</div>
					</template>
				</el-table-column>
				<el-table-column label="备注">
					<template slot-scope="scope">
						<div>{{scope.row.mark}}</div>
					</template>
				</el-table-column>
				<el-table-column label="审核状态" width="190px">
					<template slot-scope="scope">
						<div v-if="scope.row.is_owner==2">
							<div>{{scope.row.check_status_text}} 
							<el-button v-if="scope.row.check_status==1" @click="check_pass(scope.row)" size="mini" type="text">通过</el-button>
							<el-button v-if="scope.row.check_status==1" @click="check_unpass(scope.row)" size="mini" type="text" style="color:red">驳回</el-button>
							<el-button v-if="scope.row.check_status==2" @click="check_unpass(scope.row)" size="mini" type="text" style="color:red">驳回</el-button>
							<el-button v-if="scope.row.check_status==3" @click="check_pass(scope.row)" size="mini" type="text">通过</el-button>
							<el-button @click="show_relation_proof(scope.row)" size="mini" type="text">查看凭证</el-button>
						</div>
						</div>
					</template>
				</el-table-column>
				
				<!-- 操作行 -->
				<el-table-column label="操作" width="140px">
					<template slot-scope="scope">
						<el-button @click="unbind(scope.row)" size="mini" type="text">解绑</el-button>
						<el-button @click="banked_edit(scope.row)" size="mini" type="text">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
		
		<!-- 绑定弹窗 -->
		<bind
			:is_show="bind_is_show"
			@get_page_data="get_page_data"
		></bind>

        <!-- 编辑 -->
		<edit :is_show="bankcard_edit.is_show" :id="bankcard_edit.id"
		></edit>
		<!-- 凭证弹窗 -->
		<el-dialog
			top="2vh"
			width="800px"
			title="关系凭证"
			:visible.sync="relation_proof.is_show"
		>
			<el-image 
			    :z-index="10000"
				style="width: 400px; height: 400px"
				:src="relation_proof.src" 
				:preview-src-list="relation_proof_src_list">
			</el-image>
			<!-- <img :src="relation_proof.src" style="width:100%"> -->
		</el-dialog>
	</div>
</template>

<script>
	import bind from './bind.vue'
	import edit from './edit.vue'
	export default {
		components:{
			bind,
			edit
		},
		data() {
			return {

				//搜索条件
				form: {
					app_user_tel:'',//电话
					app_user_id_card_num:'',//身份证号
					card_num:'',//银行卡号
					card_user_name:'',//
					card_user_tel:'',//
					relation:'',//
					check_status:'',//
					is_owner:'',//
					mark:'',//备注搜索
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},
				bankcard_check_list:[],//批量通过银行卡
				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//添加弹出层
				bind_is_show:0,

				//关系凭证弹出层
				relation_proof:{

					//是否显示
					is_show:false,
					
					//地址
					src:''
				},
				//编辑数据
				bankcard_edit:{
					is_show:0,
					id:"",
				},
				relation_proof_src_list:[]
			}
		},
		created() {
			
			//获取页面数据
			this.get_page_data()
		},
		methods: {
			batch_open(){
				if(this.bankcard_check_list.length<=0){
					this.$my.other.msg({
						type:"info",
						str:'请先选择银行卡'
					});
					return
				}
				let ids = []
				this.bankcard_check_list.forEach(item=>{
					ids.push(item.id)
				})
				this.$my.other.confirm({
					content:'确定审核通过?',
					confirm:()=>{

						//调接口
						this.$my.net.req({
							data:{
								mod:'app_user',
								ctr:'bankcard_check_pass_admin',
								ids
							},
							callback:(data)=>{

								this.$my.other.msg({
									type:"success",
									str:'操作成功'
								});
								
								//刷新
								this.get_page_data()
							}
						});
					}
				});
			},
			//勾选数据
			bankcard_check_pass(item){
				this.bankcard_check_list = item
			},
			//编辑
			banked_edit(item){
				this.bankcard_edit.id = item.id
				this.bankcard_edit.is_show++
			},
			//打开添加页面
			open_add_view(){
				
				//打开弹出层
				this.bind_is_show++;
			},

			//显示关系凭证
			show_relation_proof(item){
				if(item.relation_proof){
					this.relation_proof.src=this.$my.qiniu.make_src('bankcardrelationproof',item.relation_proof),
					this.relation_proof_src_list[0] = this.relation_proof.src
					this.relation_proof.is_show=true;
				}
			},

			//解绑
			unbind(item){

				this.$my.other.confirm({
					content:'确定解绑?',
					confirm:()=>{

						//调接口
						this.$my.net.req({
							data:{
								mod:'app_user',
								ctr:'bankcard_unbind_admin',
								id:item.id
							},
							callback:(data)=>{

								this.$my.other.msg({
									type:"success",
									str:'操作成功'
								});
								
								//刷新
								this.get_page_data()
							}
						});
					}
				});
			},

			//审核通过
			check_pass(item){
				
				//询问
				this.$my.other.confirm({
					content:"点击'确定'审核通过",
					confirm:()=>{

						//调接口
						this.$my.net.req({
							data:{
								mod:'app_user',
								ctr:'bankcard_check_pass_admin',
								id:item.id
							},
							callback:(data)=>{

								this.$my.other.msg({
									type:"success",
									str:'操作成功'
								});
								
								//刷新
								this.get_page_data()
							}
						});
					}
				});
			},

			//审核驳回
			check_unpass(item){
				this.$prompt('请输入驳回原因', {
					}).then(({ value }) => {
						this.$my.net.req({
							data:{
								mod:'app_user',
								ctr:'bankcard_check_unpass_admin',
								id:item.id,
								reject:value
							},
							callback:(data)=>{

								this.$my.other.msg({
									type:"success",
									str:'操作成功'
								});
								
								//刷新
								this.get_page_data()
							}
						});
					}).catch(() => {    
				});
				//询问
				// this.$my.other.confirm({
				// 	content:"点击'确定'审核驳回",
				// 	confirm:()=>{

				// 		//调接口
				// 		this.$my.net.req({
				// 			data:{
				// 				mod:'app_user',
				// 				ctr:'bankcard_check_unpass_admin',
				// 				id:item.id
				// 			},
				// 			callback:(data)=>{

				// 				this.$my.other.msg({
				// 					type:"success",
				// 					str:'操作成功'
				// 				});
								
				// 				//刷新
				// 				this.get_page_data()
				// 			}
				// 		});
				// 	}
				// });
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					app_user_tel:'',//电话
					app_user_id_card_num:'',//身份证号
					card_num:'',//银行卡号
					card_user_name:'',//
					card_user_tel:'',//
					relation:'',//
					check_status:'',//
					is_owner:'',//
					mark:"",
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取后台用户
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'bankcard_list_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//审核状态
							switch(item.check_status){
								case '1':item.check_status_text="待审核";break;
								case '2':item.check_status_text="审核通过";break;
								case '3':item.check_status_text="审核未通过";break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//用户修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>